export default [
  {
    key: "companyId",
    label: "field.company",
    rules: "required",
    type: "asyn-single-selection",
    repository: "company",
    selectionKey: "id",
    selectionLabel: "name",
  },
  {
    key: "code",
    label: "field.code",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "name",
    label: "field.name",
    rules: "required|max:100",
    type: "text",
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
